<template>
<div>
    <v-list dense>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>{{ $t('teams') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
                <v-btn
                    plain
                    icon
                    to="/teams/new"
                    :title="$t('addTeam')"
                    >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-divider/>

        <v-list-item v-for="team in sortedTeams" :key="team.id"
            class="pl-8"
            :input-value="team.id == tenant.id"
            dense
            @click="onSelect(team)"
        >
            <v-list-item-content :class="{ 'active-list-item': team.id === tenant.id }">
                <v-list-item-title>{{ team.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="team.organization" class="caption">{{ team.organization.name }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="my-0">
                <team-action-menu :team="team"/>
            </v-list-item-action>
        </v-list-item>
    </v-list>

    <v-list dense class="pb-6 mb-14">
        <v-divider/>
        <v-list-item color="red">
            <v-list-item-content >
                <v-list-item-title color="pink">{{ $t('organizations') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="my-0">
                <v-btn icon
                    size="small"
                >
                    <v-icon small>mdi-sitemap</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>

        <v-divider/>
        <v-list-item v-for="o in sortedOrganizations" :key="o.id"
            class="pl-8"
            @click="onSelect(o)"
            :input-value="o.id == tenant.id"
            dense
        >
            <v-list-item-content>
                <v-list-item-title>{{ o.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex';
import TeamActionMenu from './team-action-menu.vue'

export default {
    components: {
        TeamActionMenu
    },
    data(){
        return {
            sortedTeams2: []
        }
    },
    computed: {
        ...mapGetters('user', ['user', 'teams', 'tenant', 'organizations']),
        sortedTeams(){
            return  _.uniqBy(_.orderBy(this.teams, ['name'], ['asc']), 'id')
        },
        sortedOrganizations(){
            if(this.organizations && this.organizations.length > 0){
              return this.organizations.slice().sort((a, b) => {
                  return a.name.localeCompare(b.name)
              })
            }
            return []
        }
    },
    methods:{
        ...mapActions('user', ['setTenant']),
        async onSelect(team) {

            await this.setTenant(team)
            this.$router.push({ name:'team-select', params: { id: team.id}, query: { to: '/'}})
        },
        async manageTeam(team){
            await this.setTenant(team)
            await this.$router.push({name: 'team-detail', params: { id: team.id}})
        }
    }
}
</script>
