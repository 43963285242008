<template>
  <div class="d-flex align-center">
    <v-toolbar-items v-if="isAuthenticated">
      <v-menu bottom left offset-y v-model="showMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain v-bind="attrs" v-on="on">
            <v-icon x-small>mdi-account</v-icon>
            <span class="pl-1 pr-1">{{ user.name }} </span>
            <v-icon x-small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link :href="profileLink" title="Profile">
            <v-list-item-icon>
              <v-icon small>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('userProfile') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> 
          
          <v-list-item v-if="isTeamSelected" link :href="teamLink" :title="$t('teamViewMenu')">
            <v-list-item-icon>
              <v-icon small>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('teamViewMenu') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> 
         
          <v-list-item @click.stop="doLogout">
            <v-list-item-icon>
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>     
          <v-divider></v-divider>     
          <v-list-item @click.stop="help" title="Help">
            <v-list-item-icon>
              <v-icon small>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('help') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>

    <v-toolbar-items v-if="!isAuthenticated">
      <v-btn icon :title="$t('signIn')" @click="login">
        <v-icon small>sign-in-alt</v-icon>
      </v-btn>
    </v-toolbar-items>

    <v-toolbar-items>
      <locale-selector />
    </v-toolbar-items>

    <v-toolbar-items v-if="isAuthenticated">
      <message-notifications :tenantId="tenantId" :user="user" />
    </v-toolbar-items>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import profileMixin from '@/components/mixins/profile'
import AuthService from '@/services/auth.service.js'
import LocaleSelector from '@/components/i18n/locale-selector.vue'

export default {
  mixins: [profileMixin],
  components: {
    LocaleSelector,
    MessageNotifications: () => import('@/messaging/components/notifications-menu/index')
  },
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapGetters('app', ['config']),
    ...mapGetters('user', ['tenantId', 'user', 'isAuthenticated', 'hasMultipleTenants', 'isTeamSelected']),
    errorTooltip() {
      return `${this.errors.length} errors`
    },
    teamLink(){
      return `/teams/${this.tenantId}`
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    login() {
      AuthService.login()
    },
    async doLogout() {
      await this.logout()
    },
    help() {
      window.open(this.config.helpUrl, '_blank')
    },
    show() {
      this.showMenu = true
    },
    viewErrors() {
      let msg = 'ToDo: move this to top level view\n'
      for (let i = 0; i < this.errors.length; i++) {
        msg = `${msg} ${i + 1}: ${this.errors[i].message}\n`
      }
      alert(msg)
    }
  }
}
</script>

<style>
.flyout-drawer {
  width: 300px;
}
</style>
