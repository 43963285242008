<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon 
            size="small" 
            v-bind="attrs"
            v-on="on"
        >
            <v-icon small>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list width="200" dense>
        <v-list-item @click="manageTeam">
          <v-list-item-title>{{ $t('manage') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="view">
          <v-list-item-title>{{ $t('view') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
import { mapActions } from 'vuex';
export default{
    props: {
        team: { type:Object, required: true}
    },
    methods: {
        ...mapActions('user', ['setTenant']),
        async manageTeam(){
            await this.setTenant(this.team)
            await this.$router.push({name: 'team-detail', params: { id: this.team.id}})
        }, 
        async view(){
            await this.setTenant(this.team)
            this.$router.push({ name:'team-select', params: { id: this.team.id}, query: { to: '/'}})
        }
    }
}
</script>