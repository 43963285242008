<template>
  <div>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini"
    app
    clipped
    :permanent="modelValue"
  >
    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        link
        :title="$t('teams')"
        @click.stop="toggleTeamDrawer"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('teams') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider/>
      <v-list-item link :title="$t('home')" :to="{ name: 'maps-index' }">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('home') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        :title="$t('collections')"
        :to="{ name: 'collections-index' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-folder-table</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('collections') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="hasFeature('messages') == true"
        link
        :title="$t('messages')"
        :to="{ name: 'messaging-home' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-message-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('messages') }}<v-badge
                v-if="unreadMessageCount > 0"
                color="error"
                :content="unreadMessageCount"
                class="pl-2"
              >
              </v-badge></v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :title="$t('people')" :to="{ name: 'people-index' }" v-if="hasFeature('personTracker') == true">
        <v-list-item-icon>
          <v-icon>mdi-briefcase-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('people') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        :title="$t('packageUploads')"
        :to="{ name: 'packages-index' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-package-up</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('packageUploads') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

    <template v-slot:append>
      <v-system-bar>
        <v-spacer></v-spacer>
        <message-connection-status v-if="!isCollapsed" />
      </v-system-bar>
    </template>
  </v-navigation-drawer>

  <v-navigation-drawer
    v-model="teamDrawer"
    app
    floating
    absolute
    temporary
    max-width="550"
    width="300"
    :value="drawer"
    class="flyout-drawer"
  >
    <team-nav-list/>
  </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profileMixin from '@/components/mixins/profile'
import messageConnectionStatus from '@/messaging/components/connection-status'
import teamNavList from '@/components/teams/team-nav.vue'

export default {
  mixins: [profileMixin],
  components: {
    messageConnectionStatus,
    teamNavList
  },
  props: {
    modelValue: { type: Boolean, default: true },
    chromeless: { type: Boolean, default: false },
    minify: { type: Boolean, default: false }
  },
  data() {
    return {
      drawer: true,
      isCollapsed: false,
      isExpanded: false,
      mini: false,
      teamDrawer: false,
    }
  },
  computed: {
    ...mapGetters('user', ['organization', 'hasFeature']),
    ...mapGetters('theme', ['theme']),
    ...mapGetters('messaging', ['unreadMessageCount']),
  },
  watch: {
    modelValue: function(newVal) {
      this.drawer = newVal
    },
    minify: function(newVal){
      this.mini = !newVal
      this.toggle()
    },
    $route() {
      if(this.$vuetify.breakpoint.smAndDown){
        console.log('$route change', { drawer: this.drawer, smAdnDown: this.$vuetify.breakpoint.smAndDown})
        this.drawer = false;
        this.$emit('update:modelValue', this.drawer)
      }
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.mini = this.chromeless
  },
  methods: {
    toggle() {
      console.log('nav-drawer toggle', this.mini)
      if (this.mini) {
        this.isExpanded = true
        this.isCollapsed = false
        this.mini = false
      } else {
        this.isExpanded = false
        this.isCollapsed = true
        this.mini = true
      }
      this.$emit('update:minify', this.minify)
    },
    onResize() {
      if (this.isCollapsed || (this.isExpanded && this.$vuetify.breakpoint.smAndDown)){
        return
      }

      this.isCollapsed = false
      this.isExpanded = false
      this.$emit('update:minify', this.minify)
    },
    drawerChange() {
      console.log(this.drawer)
      this.drawer = !this.drawer
    },
    toggleTeamDrawer() {
      this.teamDrawer = !this.teamDrawer
    },
  }
}
</script>

<style></style>
