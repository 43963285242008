<template>
  <v-app>
    <app-guard>

    <message-wrapper>
      <v-app-bar app color="#D1D3D4" clipped-left extension-height="28" elevation="1">
        <v-app-bar-nav-icon @click.stop="goHome">
          <v-img
            :alt="applicationName"
            class="shrink mr-2 ml-4"
            contain
            :src="logoUrl"
            transition="scale-transition"
            width="40"
          />
        </v-app-bar-nav-icon>
        <v-toolbar-title id="title" v-if="$vuetify.breakpoint.mdAndUp">
          <div class="d-flex flex-column">
            <span>{{ applicationName }}</span>
          </div>
        </v-toolbar-title>        

        <v-spacer></v-spacer>
        <login-info />
        <template #extension>
          <v-toolbar short dense flat v-if="!isSmallView" elevation="1" height="32">
            <v-app-bar-nav-icon
              color="black"
              @click.stop="toggleDrawer"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="subtitle-1 ml-0 pl-0">{{ tenantDisplayName }}</v-toolbar-title>            
            <v-navigation-drawer
              v-model="teamDrawer"
              app
              floating
              absolute
              temporary
              max-width="550"
              width="300"
              :value="drawer"
              class="flyout-drawer"
            >
              <team-nav-list/>
            </v-navigation-drawer>
 
            <v-spacer></v-spacer> 
          </v-toolbar>
          
        </template>
      </v-app-bar>

      <nav-drawer v-if="isAuthenticated && !chromeless" v-bind:modelValue.sync="drawer" v-bind:minify.sync="mini" />

      <v-main>
        <v-container fluid>
          <transition
            name="routerAnimation"
            enter-active-class="animated faster fadeIn"
          >
            <router-view></router-view>
          </transition>
        </v-container>
      </v-main>

      <notifications></notifications>
    </message-wrapper>
  </app-guard>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginInfo from '@/components/layout/login-info'
import NavDrawer from '@/components/layout/nav-drawer'
import Notifications from '@/components/layout/app-notifications'
import MessageWrapper from '@/messaging/components/message-wrapper.vue'
import AppGuard from '@/components/layout/app-guard'
import TeamNavList from '@/components/teams/team-nav.vue'

export default {
  name: 'App',

  components: {
    AppGuard,
    LoginInfo,
    MessageWrapper,
    NavDrawer,
    Notifications,
    TeamNavList
  },
  data() {
    return {
      drawer: !this.$vuetify.breakpoint.smAndDown,
      teamDrawer: false,
      items: [
        { title: 'Home' },
        { title: 'About' },
        { title: 'Contact' },
      ],
      mini: false
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('user', ['isAuthenticated', 'tenantId', 'user', 'applicationName', 'logoUrl', 'faviconUrl', 'tenant']),
    chromeless() {
      return this.$route.meta.chromeless || this.$route.query.chromeless
    }, 
    tenantDisplayName(){
      if(this.tenant.organization){
        return `${this.tenant.organization.name} / ${this.tenant.name}`  
      }

      return this.tenant.name
    },
    isSmallView(){
      if(!this.$vuetify){
        console.log('barf')
      }
      return this.$vuetify.sm || this.$vuetify.xs
    }
  },
  created() {
    this.setFavicon()
  },
  mounted() {},
  // errorCaptured() {
  //   this.snackbar = true
  //   return false
  // },
  methods: {
    goHome() {
      this.$router.push({ path: '/' })
    },
    setFavicon() {
      const favicon = document.getElementById('favicon')
      favicon.href = this.faviconUrl
    },     
    toggleDrawer(){
      if(this.$vuetify.breakpoint.mdAndUp){
        console.log('toggleDrawer mdAndUp', this.$vuetify.breakpoint.mdAndUp)
        this.mini = !this.mini
        this.drawer = true
      }
      if(this.$vuetify.breakpoint.smAndDown){
        console.log('toggleDrawer smAndDown', this.$vuetify.breakpoint.smAndDown)
        this.drawer = !this.drawer    
        this.mini = false    
      }      
    }, 
    toggleTeamDrawer() {
      this.teamDrawer = !this.teamDrawer
    },
  }
}
</script>

<style scoped>
main div {
  height: 100%;
}

::v-deep .v-breadcrumbs__item {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .modal {
  z-index: 9999;
}

::v-deep .v-toolbar__extension {
  padding: 0 !important;
}

#title {
  color: #475569;
}

::v-deep .card-bottom-actions {
  min-width: 150px;
  min-height: 150px;
  padding-bottom: 50px;
}
::v-deep .card-actions-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.text-no-wrap{
  white-space: nowrap;
  overflow: hidden;
}
.flyout-drawer {
  width: 250px;
}
</style>
